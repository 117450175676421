import React, { useEffect, useState } from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import SmallBanner from "@components/common/SmallBanner";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import Products from "@components/sections/Products";
import dayjs from "dayjs";
import axios from "axios";
import useSWR from "swr";
import ProductsNew from "@components/sections/products2/Products";

const endpoints = {
    products: "/.netlify/functions/products",
};

// Función para filtrar y ordenar los productos
const filterAndSortProducts = (items, filters, negativeFilters) => {
    let products = items
        .filter(x => x.enabled && !x.hidden)
        .map(p => {
            const { created_at, updated_at, ...rest } = p;
            return {
                ...rest,
                created_at: dayjs(created_at).toDate(),
                updated_at: dayjs(updated_at).toDate(),
            };
        });

    // Aplicamos los filtros
    if (filters?.length) {
        products = products.filter(p => {
            return filters.every(f => p.category?.includes(f));
        });
    }

    // Aplicamos los filtros negativos
    if (negativeFilters?.length) {
        products = products.filter(p => {
            return negativeFilters.every(f => !p.category?.includes(f));
        });
    }

    // Ordenamos por stock y fecha de creación
    return products.sort((a, b) => b.stock - a.stock || b.created_at - a.created_at);
};

const Tienda = () => {
    const {
        data: result,
        isLoading,
        isValidating,
    } = useSWR(endpoints.products, axios.get, {
        dedupingInterval: 30 * 1000,
    });

    const [mossosBooksState, setMossosBooksState] = useState([]);
    const [gubBooksState, setGubBooksState] = useState([]);
    const [plBooksState, setPlBooksState] = useState([]);
    const [psicosBooksState, setPsicosBooksState] = useState([]);
    const [cpBooksState, setCpBooksState] = useState([]);
    const [othersBooksState, setOthersBooksState] = useState([]);

    const [packsMossosBooksState, setPacksMossosBooksState] = useState([]);
    const [packsGubBooksState, setPacksGubBooksState] = useState([]);
    const [packsPlBooksState, setPacksPlBooksState] = useState([]);
    const [packsPsicosBooksState, setPacksPsicosBooksState] = useState([]);
    const [packsCpBooksState, setPacksCpBooksState] = useState([]);
    const [packsOthersBooksState, setPacksOthersBooksState] = useState([]);

    useEffect(() => {
        if (result?.data?.products) {
            // Busamos libros de Mossos d'Esquadra
            setMossosBooksState(
                filterAndSortProducts(
                    result.data.products,
                    ["books", "mossos"],
                    ["packs", "cp", "gub", "pl", "psicos"],
                ),
            );

            // Busamos libros de la Guàrdia Urbana de Barcelona
            setGubBooksState(
                filterAndSortProducts(
                    result.data.products,
                    ["books", "gub"],
                    ["packs", "psicos", "cp", "mossos", "pl", "cp"],
                ),
            );

            // Busamos libros de la Policia Local
            setPlBooksState(
                filterAndSortProducts(
                    result.data.products,
                    ["books", "pl"],
                    ["packs", "psicos", "cp", "mossos", "gub", "cp"],
                ),
            );

            // Busamos libros de Psicotécnicos
            setPsicosBooksState(
                filterAndSortProducts(result.data.products, ["books", "psicos"], ["packs"]),
            );

            // Busamos libros de Casos Prácticos
            setCpBooksState(
                filterAndSortProducts(result.data.products, ["books", "cp"], ["packs"]),
            );

            // Busamos libros de Otras temáticas
            setOthersBooksState(
                filterAndSortProducts(
                    result.data.products,
                    ["books"],
                    ["packs", "cp", "mossos", "gub", "pl", "psicos", "cp"],
                ),
            );

            // Busamos packs de Mossos d'Esquadra
            setPacksMossosBooksState(
                filterAndSortProducts(
                    result.data.products,
                    ["packs", "mossos", "books"],
                    ["cp", "gub", "pl", "psicos"],
                ),
            );

            // Busamos packs de la Guàrdia Urbana de Barcelona
            setPacksGubBooksState(
                filterAndSortProducts(
                    result.data.products,
                    ["packs", "gub", "books"],
                    ["cp", "mossos", "pl", "psicos"],
                ),
            );

            // Busamos packs de la Policia Local
            setPacksPlBooksState(
                filterAndSortProducts(
                    result.data.products,
                    ["packs", "pl", "books"],
                    ["cp", "mossos", "gub", "psicos"],
                ),
            );

            // Busamos packs de Psicotécnicos
            setPacksPsicosBooksState(
                filterAndSortProducts(
                    result.data.products,
                    ["packs", "psicos", "books"],
                    ["mossos", "gub", "pl", "cp"],
                ),
            );

            // Busamos packs de Casos Prácticos
            setPacksCpBooksState(
                filterAndSortProducts(
                    result.data.products,
                    ["packs", "cp", "books"],
                    ["mossos", "gub", "pl", "psicos"],
                ),
            );

            // Busamos packs de Otras temáticas
            setPacksOthersBooksState(
                filterAndSortProducts(
                    result.data.products,
                    ["packs", "books"],
                    ["mossos", "gub", "pl", "psicos", "cp"],
                ),
            );
        }
    }, [result]);

    return (
        <>
            <Layout>
                <Seo
                    title="Nuestra tienda: Libros"
                    description="Más rápido, en menos tiempo y directo a lo imprescindible. Ahorra tiempo y fortalece tus conocimientos."
                />

                <SmallBanner
                    title="Libros"
                    subtitle="Más rápido, en menos tiempo y directo a lo
                    imprescindible. Ahorra tiempo y fortalece tus
                    conocimientos."
                    filename="bgs/bgBooks4"
                />

                <div className="container lg:px-0 pt-20 pb-10">
                    <Title>Libros disponibles</Title>
                    <Text className="text-justify">
                        Más rápido, en menos tiempo y directo a lo imprescindible. Ahorra tiempo y
                        fortalece tus conocimientos.
                    </Text>
                </div>

                <div className="container lg:px-0 pt-0 pb-20 space-y-20">
                    <div>
                        <h2 className={styles.subtitle}>Mossos d'Esquadra</h2>

                        <div className="mt-10 space-y-10">
                            {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                <ProductsNew
                                    items={mossosBooksState}
                                    loading={isLoading || isValidating}
                                />
                            ) : (
                                <Products items={mossosBooksState} />
                            )}
                        </div>
                    </div>

                    <div>
                        <h2 className={styles.subtitle}>Guàrdia Urbana de Barcelona</h2>

                        <div className="mt-10 space-y-10">
                            {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                <ProductsNew
                                    items={gubBooksState}
                                    loading={isLoading || isValidating}
                                />
                            ) : (
                                <Products items={gubBooksState} />
                            )}
                        </div>
                    </div>

                    <div>
                        <h2 className={styles.subtitle}>Policía Local</h2>

                        <div className="mt-10 space-y-10">
                            {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                <ProductsNew
                                    items={plBooksState}
                                    loading={isLoading || isValidating}
                                />
                            ) : (
                                <Products items={plBooksState} />
                            )}
                        </div>
                    </div>

                    <div>
                        <h2 className={styles.subtitle}>Casos Prácticos</h2>

                        <div className="mt-10 space-y-10">
                            {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                <ProductsNew
                                    items={cpBooksState}
                                    loading={isLoading || isValidating}
                                />
                            ) : (
                                <Products items={cpBooksState} />
                            )}
                        </div>
                    </div>

                    <div>
                        <h2 className={styles.subtitle}>Psicotécnicos</h2>

                        <div className="mt-10 space-y-10">
                            {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                <ProductsNew
                                    items={psicosBooksState}
                                    loading={isLoading || isValidating}
                                />
                            ) : (
                                <Products items={psicosBooksState} />
                            )}
                        </div>
                    </div>

                    <div>
                        <h2 className={styles.subtitle}>Otros</h2>

                        <div className="mt-10 space-y-10">
                            {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                <ProductsNew
                                    items={othersBooksState}
                                    loading={isLoading || isValidating}
                                />
                            ) : (
                                <Products items={othersBooksState} />
                            )}
                        </div>
                    </div>
                </div>

                <div className="container lg:px-0 pt-20 pb-10">
                    <Title>Packs libros</Title>
                    <Text className="text-justify">
                        ¡Ahorra más dinero con nuestros packs! Obtén más por menos y aprovecha esta
                        oportunidad única para alcanzar el éxito en tu oposición.
                    </Text>
                </div>

                <div className="container lg:px-0 pt-0 pb-20 space-y-20">
                    <div>
                        <h2 className={styles.subtitle}>Mossos d'Esquadra</h2>

                        <div className="mt-10 space-y-10">
                            {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                <ProductsNew
                                    items={packsMossosBooksState}
                                    loading={isLoading || isValidating}
                                />
                            ) : (
                                <Products items={packsMossosBooksState} />
                            )}
                        </div>
                    </div>

                    <div>
                        <h2 className={styles.subtitle}>Guàrdia Urbana de Barcelona</h2>

                        <div className="mt-10 space-y-10">
                            {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                <ProductsNew
                                    items={packsGubBooksState}
                                    loading={isLoading || isValidating}
                                />
                            ) : (
                                <Products items={packsGubBooksState} />
                            )}
                        </div>
                    </div>

                    <div>
                        <h2 className={styles.subtitle}>Policía Local</h2>

                        <div className="mt-10 space-y-10">
                            {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                <ProductsNew
                                    items={packsPlBooksState}
                                    loading={isLoading || isValidating}
                                />
                            ) : (
                                <Products items={packsPlBooksState} />
                            )}
                        </div>
                    </div>

                    <div>
                        <h2 className={styles.subtitle}>Casos Prácticos</h2>

                        <div className="mt-10 space-y-10">
                            {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                <ProductsNew
                                    items={packsCpBooksState}
                                    loading={isLoading || isValidating}
                                />
                            ) : (
                                <Products items={packsCpBooksState} />
                            )}
                        </div>
                    </div>

                    <div>
                        <h2 className={styles.subtitle}>Psicotécnicos</h2>

                        <div className="mt-10 space-y-10">
                            {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                <ProductsNew
                                    items={packsPsicosBooksState}
                                    loading={isLoading || isValidating}
                                />
                            ) : (
                                <Products items={packsPsicosBooksState} />
                            )}
                        </div>
                    </div>

                    <div>
                        <h2 className={styles.subtitle}>Otros</h2>

                        <div className="mt-10 space-y-10">
                            {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                <ProductsNew
                                    items={packsOthersBooksState}
                                    loading={isLoading || isValidating}
                                />
                            ) : (
                                <Products items={packsOthersBooksState} />
                            )}
                        </div>
                    </div>
                </div>

                <div className={styles.vatMessage}>* I.V.A. incluido en todos los precios</div>
            </Layout>
        </>
    );
};

const styles = {
    subtitle:
        "font-semibold text-cpurple uppercase text-lg border-b-2 border-cpurple pb-1 mb-5 border-opacity-50",
    vatMessage: "mt-10 text-right text-xs italic font-montserrat text-gray-500",
};

export default Tienda;
